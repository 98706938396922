/**
 * Fix for https://bridebook.atlassian.net/browse/LIVE-19066
 * Apparently sometimes AppsFlyer script does not load styles properly. This is a workaround.
 */
@font-face {
  font-family: Museo Sans;
  src: url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.eot');
  src: url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.woff2') format('woff2'),
    url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.woff') format('woff'),
    url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.ttf') format('truetype'),
    url('https://cdn.appsflyer.com/creatives-fonts/museo_sans/MuseoSans_700-webfont.svg#museo_sans500') format('svg');
  font-style: normal;
}

.responsive-wrapper * {
  font-family: Museo Sans, sans-serif;
}

@media only screen and (min-width: 200px) and (max-width: 360px) {
  .responsive-wrapper {
    font-size: 8px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 390px) {
  .responsive-wrapper {
    font-size: 10px;
  }
}

@media only screen and (min-width: 391px) {
  .responsive-wrapper {
    font-size: 12px;
  }
}

.grecaptcha-badge {
  display: none;
}

#webWidget {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.box {
  display: flex;
  position: relative;
  flex-direction: column;
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-right: env(safe-area-inset-left);
  --safe-area-inset-left: env(safe-area-inset-left);
}

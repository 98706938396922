/* rstdi = react-swipe-to-delete-ios */

.rstdi {
  --rstdiHeight: 30px;
  --rstdiMinHeight: 30px;
  --rstdiTransitionDuration: 250ms;
  --rstdiTranslate: 0px;
  --rstdiIsRtl: 0;
  --rstdiDeleteColor: rgba(252, 58, 48, 1);
  --rstdiDeleteWidth: 75px;
  --rstdiButtonMarginRight: 0px;
  --rstdiButtonMarginLeft: 0px;

  width: auto;
  position: relative;
  box-sizing: border-box;
  overflow: initial;
  overflow-x: hidden;
  height: var(--rstdiHeight);
  max-height: calc(var(--rstdiHeight) + 10px);
  min-height: var(--rstdiMinHeight);
}

.rstdi *,
.rstdi *:before,
.rstdi *:after {
  box-sizing: border-box;
}

.rstdi.performing {
  transition: all var(--rstdiTransitionDuration) ease-out;
  max-height: 0;
}

.rstdi .content {
  height: 100%;
  width: auto;
  position: relative;
  transform: translateX(var(--rstdiTranslate));
}

.rstdi .content.transition {
  transition: transform var(--rstdiTransitionDuration) ease-out;
}

.rstdi .content.performing {
  height: 100%;
  width: auto;
  position: relative;
  transform: scale(0) translateX(var(--rstdiTranslate));
}

.rstdi .confirm-action {
  position: absolute;
  left: 100%;
  height: 100%;
  width: 300%;
  top: 0;
  background: var(--rstdiDeleteColor);
  font-weight: 400;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.rstdi .confirm-action button {
  width: var(--rstdiDeleteWidth);
  transition: all transform var(--rstdiTransitionDuration) ease-in-out;
  margin-left: 0;
  margin-right: auto;
  text-align: center;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}
